import React, { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { PuffLoader } from "react-spinners";
import AOS from "aos";
import "aos/dist/aos.css";
import Benefits from "../components/Benefits";
import Header from "../components/Header";
import Sam from "../components/Sam";
import Footer from "../components/Footer";

const HomePage = () => {

  const [loading, setLoading] = useState(true);
  const [imgOffset, setImgOffset] = useState(null);

  useEffect(() => {
    AOS.init({duration : 1500, once: true});
  })

  return (
    <div className="home-page" style={{overflow: loading ? "hidden" : undefined}}>
      {loading && <Div100vh className="loading"><PuffLoader color="white" size="180px" /></Div100vh>}
      <Header
        imgOffset={imgOffset} setImgOffset={setImgOffset} setLoading={setLoading}
      />
      <Benefits imgOffset={imgOffset} />
      <Sam />
      <Footer />
    </div>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;
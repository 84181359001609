import React from "react";
import PropTypes from "prop-types";

const Benefits = props => {

  const { imgOffset } = props;

  return (
    <section className="benefits" style={{marginTop: imgOffset}} >
      <div className="block" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out-back">
        <h2>Custom Software Solutions to give your business an edge</h2>
        <p>Deep Mountain specialises in beautiful user interfaces coupled with robust, powerful backend software.
          Initial consultations are free, and we work with you every step of the way to make sure your project
          is progressing on time, how you want it.</p>
      </div>
      <div className="block" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out-back">
        <h2>Deep Expertise in Bioinformatics</h2>
        <p>Deep Mountain specialises in software for the pharmaceutical, biotech and structural bioinformatics sectors - with ongoing and previous projects of a variety of sizes and scopes.</p>
      </div>
      <div className="block" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out-back">
        <h2>Modern Practices - Powerful, Reliable Software</h2>
        <p>We adhere to modern standards of testing and stability practices to make sure your software is not just powerful, but stable and reliable too.</p>
      </div>
    </section>
  );
};

Benefits.propTypes = {
  imgOffset: PropTypes.number
};

export default Benefits;
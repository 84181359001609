import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="name">Deep Mountain Ltd.</div>
      <div className="number">13038969</div>
    </footer>
  );
};

Footer.propTypes = {
  
};

export default Footer;
import React, { useEffect, useRef } from "react";
import Div100vh from "react-div-100vh";
import mountains from "../images/mountains.svg";
import linkedin from "../images/linkedin.svg";
import email from "../images/email.svg";

const Header = props => {

  const h1Element = useRef(null);
  const imgElement = useRef(null);
  const { imgOffset, setImgOffset, setLoading } = props;

  const resize = () => {
    const h1 = h1Element && h1Element.current;
    const img = imgElement && imgElement.current;
    if (h1 && img) {
      const height = document.querySelector(".header").offsetHeight;
      const h1Height = h1.offsetHeight;
      const imgHeight = img.offsetHeight;
      const overlap = (h1Height + imgHeight) - height;
      if (h1Height + imgHeight > height) {
        setImgOffset(overlap);
      } else if (imgOffset !== null) {
        setImgOffset(null);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", resize, null);
    resize();
    setTimeout(() => setLoading(false), 500);
  });

  return (
    <Div100vh className="header">
      <h1 ref={h1Element}>
        <span data-aos="fade-in" data-aos-delay="1800">Deep Mountain<br />Software</span>
        <div className="social-links" data-aos="fade-in" data-aos-delay="2300">
          <a href="https://www.linkedin.com/company/70398528/"><img src={linkedin} alt="linkedin"/></a>
          <a href="mailto:sam@deepmountainsoftware.com"><img src={email} alt="email"/></a>
        </div>
      </h1>
      <img
        ref={imgElement} src={mountains} alt="mountains" className="mountains"
        style={{bottom: -imgOffset || 0}} data-aos="fade-in" data-aos-delay="800" data-aos-duration="1500"
      />
    </Div100vh>
  );
};

Header.propTypes = {
  
};

export default Header;
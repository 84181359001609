import React from "react";
import PropTypes from "prop-types";
import github from "../images/github.svg";
import linkedin from "../images/linkedin.svg";
import scholar from "../images/scholar.svg";
import website from "../images/website.svg";

const Sam = props => {
  return (
    <section className="sam">
      <h2 data-aos="fade-up" data-aos-duration="1000">About Sam</h2>
      <p data-aos="fade-up" data-aos-duration="1000">
        Deep Mountain is run by Sam Ireland &#8212; a UCL PhD in Structural Bioinformatics, and developer.
        He has extensive experience with developing software solutions to bioinformatics
        problems, and in software development generally.
      </p>
      {/* <img src={sam} /> */}

      <div className="info">
        <div className="projects" data-aos="fade-up" data-aos-duration="1000">
          <h3>Bioinformatics Projects</h3>
          <a href="https://zincbind.net" className="project">
            <div className="name">ZincBindDB</div>
            <div className="description">
              A Bioinformatics resource that automatically identifies and documents zinc binding sites.
            </div>
          </a>
          <a href="https://atomium.samireland.com" className="project">
            <div className="name">atomium</div>
            <div className="description">
              A popular Python library for parsing, manipulating and saving macromolecular structures.
            </div>
          </a>
          <a href="https://zincbind.net/predict" className="project">
            <div className="name">ZincBindPredict</div>
            <div className="description">
              A suite of machine learning predictors of protein zinc binding in structure or sequence.
            </div>
          </a>
        </div>

        <div className="links" data-aos="fade-up" data-aos-duration="1000">
          <h3>Useful Links</h3>
          <a href="https://github.com/samirelanduk" className="link">
            <img src={github} alt="github" />
            <span>GitHub</span>
          </a>
          <a href="https://www.linkedin.com/in/samirelanduk/" className="link">
            <img src={linkedin} alt="linkedin" />
            <span>LinkedIn</span>
          </a>
          <a href="https://scholar.google.com/citations?user=g86HD0gAAAAJ" className="link">
            <img src={scholar} alt="google scholar" />
            <span>Google Scholar</span>
          </a>
          <a href="https://samireland.com" className="link">
            <img src={website} alt="website" />
            <span>Personal Website</span>
          </a>
        </div>
      </div>
    </section>
  );
};

Sam.propTypes = {
  
};

export default Sam;